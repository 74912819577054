import React from 'react';
import Start from '../componants/theory/keys/start/Start';


function Keys() {
	return (
		<>
		<Start />
		</>
	);
}

export default Keys;
