import React from 'react';
import Start from '../componants/theory/chords/start/Start';


function Chords() {
	return (
		<>
		<Start />
		</>
	);
}

export default Chords;